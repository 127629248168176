<template>
    <div>
        <v-layout>
            <div class="boxapp">
                <div class="boxapp-head">
                    <a href="#" @click="$router.go(-1)">
                        <faIcon type="fal" name="long-arrow-left" size="24" colorFont="#cfd2dd" />
                    </a>
                    <h1>Doações</h1>
                </div>
                <!-- boxapp body -->
                <div class="box-main">
                    <StepsVolunteering :page-options="pageOptions" :volunteering="volunteering" />
                    <div class="section list-view">
                        <!-- section head -->
                        <div class="head">
                            <h5>PASSO 6</h5>
                        </div>
                        <div class="section card-grid">
                            <!-- section head -->
                            <v-layout head justify-space-between>
                                <h3>Produtos de Doação</h3>
                                <v-btn
                                    flat
                                    round
                                    color="white"
                                    class="ml-auto btn-primary px-5 m-l-auto t-transf-none"
                                    large
                                    @click="newDonationProduct"
                                >
                                    <i class="mr-2 far fa-plus"></i>Novo Produto
                                </v-btn>
                            </v-layout>
                            <!-- section body -->
                            <div class="wrapper">
                                <!-- card item -->
                                <template v-for="item in project.donationProductList">
                                    <div v-bind:key="item.Order" class="card-item">
                                        <FileImage :file="item.file" :alt="''" />
                                        <h5>{{ item.name }}</h5>
                                        <span>{{ item.suggestionValue }}</span>
                                        <p>{{ item.description }}</p>
                                        <div class="display-flex action-card">
                                            <a
                                                @click="editDonationProduct(item)"
                                                class="edit-option"
                                            >
                                                <Icon :name="'fal fa-pen'" :size="16" />
                                            </a>
                                            <a
                                                v-if="item.active"
                                                @click="openConfirmDisableDonationProductModal(item)"
                                                class="delete-option"
                                                title="Inativar Produto"
                                            >
                                                <Icon :name="'fal fa-ban'" :size="16" />
                                            </a>
                                            <a
                                                v-else
                                                @click="activeDonationProduct(item)"
                                                class="delete-option"
                                                title="Ativar Produto"
                                            >
                                                <Icon :name="'fal fa-check'" :size="16" />
                                            </a>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-help-spacer"></div>
            <HelpBox :text="helpBoxText" />
        </v-layout>
        <div class="footer-step">
            <v-btn
                flat
                round
                :disabled="true"
                color="white"
                class="btn-default flex-inherit px-5"
                large
                v-model="pageOptions"
            >Voltar</v-btn>
            <div class="btn-right">
                <v-btn
                    flat
                    round
                    color="white"
                    class="btn-primary flex-inherit px-5"
                    large
                    v-model="pageOptions"
                    @click="nextPage"
                >Concluir</v-btn>
            </div>
        </div>
        <ValueRange
            ref="valueRangeComponent"
            v-model="project.donationProductList"
            :isTicket="false"
            :projectId="project.id"
        ></ValueRange>
        <AucAlert
            ref="donationProductAlertComponent"
            title="Você tem certeza que deseja desativar esse produto de doação?"
            confirmButtonText="Sim"
            cancelButtonText="Não"
            @confirm="disableDonationProduct(selectedDonationProduct)"
        />
    </div>
</template>

<script>

import HelpBox from "@/components/HelpBox";
import ValueRange from "@/components/ValueRange";
import ProjectService from "@/scripts/services/project.service";
import VolunteeringService from "@/scripts/services/volunteering.service";
import InstitutionService from "@/scripts/services/institution.service";
import VolunteeringAttributeOffer from "@/scripts/models/volunteeringAttributeOffer.model";
import ValidatableComponent from "@/components/base/ValidatableComponent";
import { Routes } from "@/scripts/models/enums/routes.enum";
import { HelpBoxText } from "@/scripts/models/enums/helpBoxText.enum";
import DonationProduct from "@/scripts/models/donationProduct.model";
import { ProjectTypes } from "@/scripts/models/enums/projectTypes.enum";
import AucAlert from "@/components/AucAlert";
import FileImage from "@/components/FileImage";

export default {
    extends: ValidatableComponent,
    components: { ValueRange, HelpBox, AucAlert, FileImage },
    props: ["pageOptions"],
    data() {
        return {
            helpBoxText: HelpBoxText.ProjectStep2,
            project: {},
            volunteering: {},
            breadcrumbLevels: [
                { text: "Início" },
                { text: "Projeto de Voluntariado" },
                { text: "Passo 6" },
            ],
            projectService: new ProjectService(),
            volunteeringService: new VolunteeringService(),
            institutionService: new InstitutionService(),
            volunteeringAttributeOffer: new VolunteeringAttributeOffer(),
            routes: Routes,
        };
    },
    created() {
        if (!this.$route.query.volunteeringId) {
            CommonHelper.swal("Atenção", "O Voluntariado não foi informado");
            this.$router.go(-1);
        }

        this.loadVolunteeringProject(this.$route.query.volunteeringId);
        this.loadProject(this.$route.query.volunteeringId);
        this.$emit("changeLevels", this.breadcrumbLevels);
    },
    computed: {
        isTicket() {
            return this.project.projectTypeId == ProjectTypes.Ticket;
        },
    },
    methods: {
        previousPage() {
            this.$router.push({
                name: this.routes.app.VolunteeringStep5,
                query: {
                    volunteeringId: this.project.id,
                },
            });
        },
        nextPage() {
            if (this.$route.query.institutionId && this.$route.query.entityId) {
                this.$router.push({
                    name: this.routes.app.VolunteeringList,
                    query: {
                        institutionId: this.$route.institutionId,
                        entityId: this.$route.query.entityId
                    },
                });
                return;
            }

            this.institutionService.findById(this.volunteering.institutionId).then(data => {
                this.$router.push({
                    name: this.routes.app.VolunteeringList,
                    query: {
                        institutionId: this.volunteering.institutionId,
                        entityId: data.entity.id
                    },
                });
            });
        },
        loadVolunteeringProject(volunteeringId) {
            this.volunteeringService.findById(volunteeringId).then((data) => {
                this.volunteering = data;

                this.volunteeringAttributeOfferService
                    .findByVolunteeringId(id)
                    .then((volunteeringAttributeOffers) => {
                        this.volunteering.volunteeringAttributeOffers = volunteeringAttributeOffers;
                        this.volunteeringAttributeOffers = volunteeringAttributeOffers;
                    });
            });
        },
        loadProject(volunteeringId) {
            this.projectService.findProjectByVolunteering(volunteeringId).then((data) => {
                this.project = data;

                if (this.project == null)
                    this.createProject(volunteeringId);
            });
        },
        createProject(volunteeringId) {
            this.projectService.createProjectFromVolunteering(volunteeringId).then(data => {
                this.project = data;
            });
        },
        // Donation Product
        openConfirmDisableDonationProductModal(item) {
            this.selectedDonationProduct = item;
            this.$refs.donationProductAlertComponent.open();
        },
        activeDonationProduct(item) {
            this.$refs.valueRangeComponent.changeStatus(item, true);
        },
        disableDonationProduct(item) {
            this.$refs.valueRangeComponent.changeStatus(item, false);
        },
        editDonationProduct(item) {
            this.$refs.valueRangeComponent.open(item);
        },
        newDonationProduct() {
            this.$refs.valueRangeComponent.open(new DonationProduct());
        },
        validateStep() {
            let valid = this.isValid(false);
            if (valid) this.setValidStep();
            else this.setInvalidStep();

            this.projectService.save(this.project);
        }
    }
}

</script>